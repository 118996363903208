<template>
   <div>
      <h2>Föreningen</h2>
      <p>
         Föreningen registrerades 2004 och övertog fastigheterna från tidigare ägare Stiftelsen Olle Engkvist den 1 december 2005.<br>
         Föreningen består av två fastigheter med flera olika byggnadstyper, såsom terrasshus, radhus, höghus och punkthus med tillsammans 103 bostadslägenheter varav 100 upplåtna med bostadsrätt per 2014-06-30. Till föreningen hör också ett antal lokaler varav den största disponeras av Kulturskolan i Stockholm som har sin verksamhet i den anrika biografen/konsertsalen Galjonen.
      </p>
      <p>
         Föreningens fastigheter omgärdas av grönområden som flitigt utnyttjas för lek, rekreation och trevliga grillkvällar. Två gånger per år samlas vi för att tillsammans städa grönområdena och förbereda den nya säsongen med höst- resp. vårplantering. Arbetet avslutas med trevlig samvaro och korvgrillning på hösten respektive bubblande bål inför sommarsäsongen.<br>
         En uppskattad kräftskiva avslutar sommarsäsongen.<br>
         Vintersäsongen inleder vi varje år runt allhelgonahelgen med att vi tänder upp vår vackra belysta ek.
      </p>
      <h3>Föreningens fastigheter</h3>
      <p>
         Föreningen har två fastigheter. Den ena fastigheten har beteckningen Galjonsbilden 22 och består av flera byggnader. Den andra fastigheten har beteckningen Galjonsbilden 32 och består av en byggnad. Fastigheterna ligger i idylliska Gröndal i Stockholms kommun.
      </p>
      <p>
         <b>Galjonsbilden 22</b> består av fem olika typer av byggnader:
         <ul>
            <li>Höghuset det s.k. Galjonshuset på Sjöbjörnsvägen1 med 10 våningar och 29 lägenheter.</li>
            <li>Insprängda huset, mellan terrasshusen och höghuset, Gröndalsvägen 48 med 6 lägenheter.</li>
            <li>Terrasshusen utefter Gröndalsvägen 48 – 56 i tre våningar med 26 lägenheter.</li>
            <li>Punkthuset på Jungmansgränd 2 med fyra våningar och 16 lägenheter.</li>
            <li>Radhusen på Jungmansgränd 4-24, 11 till antalet.</li>
         </ul>
         Huset på Jungmansgränd 2 uppfördes 1907, insprängda huset (Gröndalsvägen 48) uppfördes vid sekelskiftet, övriga hus uppfördes under tiden 1948-1951. Galjonshuset, terrasshusen och radhusen är ritade av den välkända arkitektduon Backström & Reinius; läs mer under rubriken "Gröndal/Arkitekterna"<br>
         Samtliga byggnader är kulturhistoriskt klassade av kommunen genom Stockholms stadsmuseum. Punkthuset på Jungmansgränd 2 är gulklassat och övriga byggnader är blåklassade.
      </p>
      <p>
         <b>Galjonsbilden 32</b> har 16 lägenheter i en trevåningsbyggnad på Sjöbjörnsvägen 7-9. Byggnaden uppfördes under tiden 1948-1951 och är grönklassad.<br>
         Av föreningens 103 bostadslägenheter var 100 upplåtna med bostadsrätt per 2014-06-30.<br>
         Fastigheterna är fullvärdesförsäkrade hos försäkringsbolaget Brandkontoret.<br>
         Den totala lägenhetsytan (bostäder och lokaler) uppgår till 9 640 kvm, varav 7 240 kvm utgör bostadsyta.
      </p>
      <p>
         Till fastigheterna hör ca 30 parkeringsplatser. Dessa är belägna utefter Jungmansgränd, Sjöbjörnsvägen 7-9 samt på planen i korsningen Gröndalsvägen – Sjöbjörnsvägen.
      </p>
   </div>
</template>

<script>
export default {
    name: "ForeningenPage"
}
</script>

<style scoped>

</style>