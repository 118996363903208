<script>
    export default {
      name: 'NavigationComponent',
      props: {
        routes: {
            type: Array,
            required: true
        }
      },
      computed: {
          enabledRoutes: function () {
              return this.routes
              .filter(route => route.enabled !== false && route.name != null)
          },
      },
      methods: {
          routeHasChildren: function(route) {
              return Array.isArray(route.children) && route.children.length > 0;
          }

      }
    }
</script>

<template>
    <header>
        <nav class="navbar navbar-expand-md navbar-light bg-light">
            <div class="container-fluid">
                <router-link to="/" class="navbar-brand">
                    <img class="d-inline-block align-top"
                        src="https://medlemmar.galjonsbilden.se/wp-content/themes/xtreme-indoor/images/logo.png"
                        width="75%"
                        heigth="75%"
                        alt="Brf Galjonsbilden 22|32"/>
                </router-link>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav">
                        <template v-for="route in enabledRoutes">
                            <template v-if="routeHasChildren(route) && route.name != null" >
                                <li class="nav-item dropdown" v-bind:key="route.path">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{ route.name }}
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <ul>
                                            <li class="nav-item" v-for="child in route.children" v-bind:key="child.path">
                                                <router-link :to=child class="dropdown-item nav-link">{{ child.name }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                            </template>
                            <template v-else>
                                <li class="nav-item" v-bind:key="route.path">
                                    <router-link :to=route class="nav-link">{{ route.name }}</router-link>
                                </li>
                            </template>
                        </template>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>
