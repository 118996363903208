<script>
import NavigationComponent from '@/components/Navbar.vue';
import FooterComponent from '@/components/Footer.vue';
import router from '@/router/routes.js';

export default {
    name: 'app',
    components: {
        Footer: FooterComponent,
        Navbar: NavigationComponent,
    },
    router,
    options: ["Foreningen"],
    data() {
        return {
            showNavbar: true,
            showHero: true,
            showNews: true,
            showFooter: true,
            fullSite: false,
            routes: router.options.routes//.filter(route => route.name != null)
        }
    }
}
 </script>

<template>
    <div id="app">
        <Navbar :routes=routes v-if="showNavbar"/>
        <main class="container">
            <section id="main">
                <router-view :fullSite="fullSite" :showHero="showHero"/>
            </section>
            <Footer v-if="showFooter"/>
        </main>
    </div>
</template>

<style scoped>
    * {
        box-sizing: border-box;
        /* border: 1px solid #FF0000; */
    }
    html {
        scroll-behavior: smooth;
    }
    img:not([alt]) {
        border: 2px solid #FF0000;
    }
</style>
