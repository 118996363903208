<template>
  <div id="booking">
    <h2>Boka tvättstugan, hobbyrummet, gästlägenheten</h2>
    <div class="alert alert-success" role="alert">
      <p>
        Från och med måndag 2023-09-25 bokas alla tider i nya bokningssystemet.
      </p>
      <ul>
        <li>Du som har bokade tider i de manuella bokningstavlorna, glöm inte att boka om dem i det digitala bokningssystemet</li>
        <li>För gästlägenheten gäller att de redan gjorda bokningarna överförs i det nya systemet av ansvarig i styrelsen</li>
      </ul>
    </div>
    <p>
      Här bokar du både tider i tvättstugan, hobbyrummet och gästlägenheten!
      Lediga tider kan du boka direkt på bokningstavlans pekskärm som hänger på väggen utanför tvättstugan.
    </p>
    <p>
      Det går också att boka tider via dator, surfplatta eller i mobilen.
      Systemet skickar push-notiser när bokningen närmar sig.
    </p>

    <h4>Så här gör du för att boka</h4>
    <p>
      Logga in via vårat bokninssystem Axema nedan.<br>
      (Användarnamnet är det 8-siffriga nummer som står på brickan, börjar med A som dock inte ska skrivas in)
    </p>
    <a role="button" class="btn btn-outline-primary btn-lg" href="https://brfgaljonsbilden2232.dyndns.org" target="_blank">Boka här</a>
  </div>
</template>

<script>
  export default {
    name: 'BookingPage'
  }
</script>
