import { render, staticRenderFns } from "./Foreningen.vue?vue&type=template&id=a01a387e&scoped=true&"
import script from "./Foreningen.vue?vue&type=script&lang=js&"
export * from "./Foreningen.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a01a387e",
  null
  
)

export default component.exports