<script>
export default {
  name: 'ContactPage'
};
</script>

<template>
   <div>
      <h2>Kontakt &#038; Felanmälan</h2>
      <div>
			<p>Här finns information om vart man ska vända sig för att anmäla fel samt kontaktuppgifter till styrelsen.</p>
         <p>
            Föreningen har uppdragit åt <em>Driftia Förvaltning AB</em> att sköta föreningen fastigheter.<br />
            Driftia tittar till våra byggnader på tisdagar och fredagar.
         </p>
         <h3>Felanmälan</h3>
         <p>
            Till Driftia anmäls t. ex fel på element, tvättstuga, portar osv. Det är viktigt att vi alla är uppmärksamma på fel och brister i våra hus och det är vars och ens ansvar att anmäla om man upptäcker något fel.
         </p>
         <p>
            Har du något fel i din lägenhet kan du även få hjälp med detta av Driftia. Kostnaden för det är din egen och inte föreningens. Gränsdragningen mellan föreningen och dig som medlem kan du läsa om under flik 5 i vår medlemspärm.
         </p>
         <p><a href="https://www.driftia.se/felanmalan">Formulär för felanmälan</a>
         <hr>
         <h4><em>Vid brand eller annan stor katastrof ring alltid 112!</em></h4>
      </div>
   </div>
</template>

<style scoped>
</style>