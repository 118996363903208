<script>
// import axios from 'axios';

// const WP_REST_URI = 'https://galjonsbilden.se/index.php/wp-json'
// console.log(WP_REST_URI)
// var wpRestApi = axios.create({
//   baseURL: WP_REST_URI,
//   timeout: 1000
// });


export default {
    name: "NewsPage",
    props: {
        showNews: Boolean,
    },
    data() {
        return {
            newsError: false,
            loading: false,
            error: false,
            news: [
                {
                    title: 'Stamspolning',
                    ingress: 'Kort text om stamspolningen',
                    article: 'Fullständig och lång text om stamspolningen som förklarar hur allt ska gå till, kontaktpersoner etc.',
                    link: 'nyheter/stamspolning', // First word of title as link
                    date: '2022-01-29'
                },
                {
                    title: 'Sotning av öppna spisar',
                    ingress: 'I föreningen finns totalt 18 st öppna spisar. Dessa behöver med jämna mellanrum sotas och rökgångar brandskyddsbesiktigas enligt brandmyndighetens tidsintervaller. Vart tredje år sker sotning och vart sjätte år sker brandskyddsbesiktningen. Just i år sammanfaller dessa besiktningar.',
                    article:
                    `
                        Den 9 december sotades och besiktigades halva beståndet och blev godkänt.
                        När resten av beståndet på Sjöbjörnsvägen 7-9 skulle besiktigas var det dessvärre tvunget att avbrytas eftersom det inte gick att sota flera av de öppna spisarna då de av olika anledningar inte kunde kommas åt.
                        För att komma åt rökgångarna på vinden behövdes det tillgång till vindsförråden vilket inte hade upplysts om och därför inte var tillgängligt.
                        
                        Ett omtag måste ske och kommer nu att ske i mellandagarna mellan jul och nyår.
                        Nytt datum för sotning och brandskyddskontroll är 28-29 december 2021.
                    `,
                    date: '2021-12-15',
                    link: 'nyheter/sotning'
                },
                {
                    title: 'Övernattningslägenhet',
                    ingress: 'Nu är äntligen övernattningslägenheten klar! Bokningen hittar via länken nedan!',
                    article: ``,
                    link: '/ellipsen',
                    date: '2021-11-01'
                }

            ],
        }
    },
    mounted () {
    console.log("Mounted to" + this);
    // wpRestApi.get('/pages')
    //     .then(response => {
    //       this.error = false
    //       return this.news = response.data;
    //     })
    //     .catch(error => {
    //       console.error(error)
    //       this.error = true
    //       this.news = null
    //     })
    //     .finally(() => this.loading = false);
  },
}
</script>

<template>
    <section id="nyheter">
        <div v-if="!newsError">
            <div v-if="newsError" id="news-error">
                <article>
                    <h2>Nyheter</h2>
                </article>
            </div>
            <div v-else id="news">
                <div v-if="loading">Loading News...</div>
                <div v-else>
                    <h2>Nyheter</h2>
                    <div class="card-deck">
                        <article v-for="item in news" v-bind:key="item" class="card">
                            <div class="card-body">
                                <h3 class="card-title">{{ item.title }}</h3>
                                <p class="card-text">{{ item.ingress }}</p>
                                <p class="card-text text-truncate" v-if="item.article">{{ item.article}}</p>
                                <a :href=item.link v-if="item.link != null" class="card-link">Läs mer...</a>
                                <p class="card-text" v-if="item.date">
                                    <small class="text-muted">{{ item.date }}</small>
                                </p>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>

</style>
