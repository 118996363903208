<script>
import DocumentsPage from '@/views/Documents';

export default {
    name: 'HomePage',
    components: {
        Documents: DocumentsPage,
    },
    props: {
        fullSite: Boolean,
        showHero: Boolean,
    },
};
</script>

<template>
  <div>
    <section
      id="header"
      class="row col-xs-6"
      v-if="showHero"
    >
      <div id="hero">
        <img
          src="https://media1.galjonsbilden.se/2014/08/terrass1_600x174.jpg"
          class="img-fluid shadow-lg mb-4"
          alt="Terasshus"
        />
      </div>
    </section>
    <h1 class="display-4 fw-bold">BRF Galjonsbilden 22|32</h1>
    <p>
      Föreningen består av två fastigheter. Den ena fastigheten har beteckningen
      Galjonsbilden 22 och består av flera byggnader. Den andra fastigheten har
      beteckningen Galjonsbilden 32 och består av en byggnad. Fastigheterna
      ligger i Stockholms kommun.
    </p>
    <Documents />
    <hr>
    <section
      v-if="fullSite"
      id="cards"
    >
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <div class="card">
            <img
              class="card-img-top"
              src="https://media1.galjonsbilden.se/2014/08/terrass1_600x174.jpg"
              alt="Card image cap"
            />
            <article class="card-body">
              <h3 class="card-title">Föreningen</h3>
              <p class="card-text">
                Föreningen registrerades 2004 och övertog fastigheterna från
                tidigare ägare Stiftelsen Olle Engkvist den 1 december 2005.
              </p>
              <a
                href="https://galjonsbilden.se/foreningen/"
                class="card-link invisible"
              >Läs mer...</a>
            </article>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="card">
            <img
              class="card-img-top"
              src="https://media1.galjonsbilden.se/2014/08/terrass1_600x174.jpg"
              alt="Card image cap"
            />
            <article class="card-body">
              <h3 class="card-title">Gröndal</h3>
              <p class="">
                Gröndal är en stadsdel i Liljeholmens stadsdelsområde inom
                Stockholms kommun. Gröndal började utvecklas som en tidig ...
              </p>
              <a
                href="https://galjonsbilden.se/om-grondal"
                class="card-link invisible"
              >Läs mer...</a>
            </article>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="card">
            <img
              class="card-img-top"
              src="https://media1.galjonsbilden.se/2014/08/terrass1_600x174.jpg"
              alt="Card image cap"
            />
            <article class="card-body">
              <h3 class="card-title">Trädgård</h3>
              <p>
                Till vår förening hör stora yttre områden mellan husen. Dessa
                används flitigt och är till stor glädje för oss medlemmar. En
                förutsättning för ...
              </p>
              <a
                href="https://galjonsbilden.se/tradgard"
                class="card-link invisible"
              >Läs mer...</a>
            </article>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6">
          <div class="card">
            <img
              class="card-img-top"
              src="https://media1.galjonsbilden.se/2014/08/terrass1_600x174.jpg"
              alt="Card image cap"
            />
            <article class="card-body">
              <h3 class="card-title">Kontakt &amp; Felanmälan</h3>
              <p class="card-text">
                Här finns information om vart man ska vända sig för att anmäla
                fel samt kontaktuppgifter till styrelsen. Föreningen har
                uppdragit ...
              </p>
              <router-link
                to="/kontakt-felanmalan"
                class="card-link"
              >
                Läs mer...
              </router-link>
            </article>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="card">
            <img
              class="card-img-top"
              src="https://media1.galjonsbilden.se/2014/08/terrass1_600x174.jpg"
              alt="Card image cap"
            >
            <article class="card-body">
              <h3 class="card-title">Filmen om Gröndal</h3>
              <p class="card-text">Från SVT arkiv kommer detta SF-reportage kring ett intressant samhällsbygge. Arkitekterna
                Sven Backström och Leif Reinius skapade ...</p>
              <a
                href="https://galjonsbilden.se/om-grondal/filmen-om-grondal"
                class="card-link btn-link invisible"
              >Läs mer...</a>
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
  .card-img-top {
    source: "https://media1.galjonsbilden.se/2014/08/terrass1_600x174.jpg"

  }
</style>
