<template>
   <div id="footer">
      <hr />
      <footer class="row">
         <address class="col">
               <strong>Postadress</strong><br>
               Brf Galjonsbilden 22|32<br>
               Jungmansgränd 2<br>
               117 67 Stockholm<br>
         </address>
         <address class="col">
               <label><strong>Organisationsnummer</strong></label><br>769611-4086<br>
         </address>
         <address class="col">
            <label><strong>Mejladress:</strong></label><br>styrelsen@galjonsbilden.se
         </address>
      </footer>
   </div>
</template>

<script>
export default {
   name: 'FooterComponent',
}
</script>>
