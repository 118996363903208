import Vue from 'vue';
import App from './App.vue'
import router from './router/routes.js';

Vue.config.productionTip = true

new Vue({
  el: "#app",
  router,
  render: h => h(App),
  mounted () {
    // wpRestApi.get('/pages')
    //     .then(response => {
    //       this.error = false
    //       return this.news = response.data;
    //     })
    //     .catch(error => {
    //       console.error(error)
    //       this.error = true
    //       this.news = null
    //     })
    //     .finally(() => this.loading = false);
  },
  computed: {
    // These computed results are cached until their reactive depdendencies changes
    cached: function() {
      return 'bar'
    }
  },
  methods: {
    // These are re-evaluated every call
    nonCached: function() {
      return 'bar'
    }
  },
});
