<script>

export default {
    // eslint-disable-next-line vue/no-unused-components
    name: 'DocumentsPage',
    data() {
        return {
            newsLetters: [
                {
                    name: "#3 2023",
                    link: "https://drive.google.com/file/d/1QLAUSGpEyZuMwy5ap3NwZQXrBww_TbZj/preview"
                },
                {
                    name: "#2 2023",
                    link: "https://drive.google.com/file/d/1QLAUSGpEyZuMwy5ap3NwZQXrBww_TbZj/preview"
                },
                {
                    name: "#1 2023",
                    link: "https://drive.google.com/file/d/1QLAUSGpEyZuMwy5ap3NwZQXrBww_TbZj/preview"
                },
                {
                    name: "#4 2022",
                    link: "https://drive.google.com/file/d/1QLAUSGpEyZuMwy5ap3NwZQXrBww_TbZj/preview"
                },
                {
                    name: "#3 2022",
                    link: "https://drive.google.com/file/d/1BP0A1hInznJjhirug14M8zY1E9DBMlhk/preview"
                },
                {
                    name: "#2 2022",
                    link: "https://drive.google.com/file/d/16GTfypfCUZtG6ofNTi0lrQsStn9CvWNP/preview"
                },
                {
                    name: "#1 2022",
                    link: "https://drive.google.com/file/d/1ia1Xe9YS1GoT088zS2xAEVJtnyJaqg9X/preview"
                },
                {
                    name: "#4 2021",
                    link: "https://drive.google.com/file/d/1CoXUcW4i-9uqyR7RgOQasYCHqHBYg91Y/preview"
                },
                {
                    name: "#3 2021",
                    link: "https://drive.google.com/file/d/1s2oFqzHG8J8En2dsZKs_3MjmdlnwutcK/preview"
                },
                {
                    name: "#2 2021",
                    link: "https://drive.google.com/file/d/1hKfwqycfK7PfrUi8z2a-oO9LYXIpkn9c/preview"
                },
                {
                    name: "#1 2021",
                    link: "https://drive.google.com/file/d/1oE3rzxEtobxirjuPQNJWIjiJ15mo9vbz/preview"
                }
            ]
        }
    }
};
</script>

<template>
    <aside id="documents">
      <hr />
      <div class="row">
        <div class="col-sm-6">
          <article class="card">
            <div class="card-img-top" />
            <div class="card-body">
              <h3 class="card-title">Årsredovisningar</h3>
              <p class="card-text">Här hittar du föreningens tidigare årsredovisningar!</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a href="https://drive.google.com/file/d/1RSK4ExfFtXneAe9wnKHYs-0DuahmpbgZ/preview">2022</a>
              </li>
              <li class="list-group-item">
                <a href="https://drive.google.com/file/d/1fAl6hc-3-M33N7Cdgt3rd9sd_R78V4Rn/preview">2021</a>
              </li>
              <li class="list-group-item">
                <a href="https://drive.google.com/file/d/1ELVL47E-rPHPw35krdOsyHuAMgZlp-bh/preview">2020</a>
              </li>
              <li class="list-group-item">
                <a href="https://media1.galjonsbilden.se/2020/09/Årsredovisning-2019-Brf-Galjonsbilden-GE.pdf">2019</a>
              </li>
              <li class="list-group-item">
                <a href="https://media1.galjonsbilden.se/2019/12/Årsredovisning-2018-Brf-Galjonsbilden-22-32-undertecknad.pdf">2018</a>
              </li>
            </ul>
          </article>
        </div>
        <div class="col-sm-6">
          <article class="card">
            <div class="card-img-top" />
            <div class="card-body">
              <h3 class="card-title">Viktiga dokument</h3>
              <p class="card-text">Här nedan hittar en samling viktiga dokument!</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a href="https://drive.google.com/file/d/15jLHc6c3WT1pcda8mMnoBBR9I2ZHjHlQ/preview">Medlemspärmen</a>
              </li>
              <li class="list-group-item">
                <a href="https://drive.google.com/file/d/1huHT7I4F9Cww8l29yeVXlIs-lhIU16sv/preview">Kulturklassning</a>
              </li>
              <li class="list-group-item">
                <a href="https://drive.google.com/file/d/1huHT7I4F9Cww8l29yeVXlIs-lhIU16sv/preview">Färg- &amp; formprogrammet</a>
              </li>
              <li class="list-group-item">
                <a href="https://drive.google.com/file/d/1PYfp1Rbhe1ZWhMxHx4aHizrTXAN7umNL/preview">Färgundersökning</a>
              </li>
            </ul>
          </article>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <article id="nyhetsbrev" class="card">
            <div class="card-img-top" />
            <div class="card-body">
              <h3 class="card-title">Nyhetsbrev</h3>
              <p class="card-text">Här hittar du föreningens senaste nyhetsbrev!</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item" v-for="item in newsLetters.slice(0,4)" v-bind:key="item.link">
                <a :href=item.link :title=item.name>{{ item.name }}</a>
              </li>
            </ul>
            <div class="card-body">
              <a href="#" class="card-link invisible">Till Nyhetsbrevsarkivet</a>
            </div>
          </article>
        </div>
      </div>
    </aside>
</template>

<style scoped>
.card-img-top {
  content: url('https://media1.galjonsbilden.se/2014/08/terrass1_600x174.jpg');
}
</style>
