import VueRouter from 'vue-router';
import Vue from 'vue';
import Home from '@/views/Home.vue';
import NewsPage from '@/views/News.vue';
import Contact from '@/views/Contact.vue';
import Booking from '@/views/Booking.vue';
import Foreningen from '@/views/foreningen/Foreningen.vue';
// import Arsredovisningar from '@/views/foreningen/Arsredovisningar.vue';
// import Forvaltning from '@/views/foreningen/Forvaltning.vue';
// import Grannsamverkan from '@/views/foreningen/Grannsamverkan.vue';
// import Kulturklassning from '@/views/foreningen/Kulturklassning.vue';
// import Styrelse from '@/views/foreningen/Styrelse.vue';
// import Grondal from '@/views/grondal/Grondal.vue';
// import GrondalFilmen from '@/views/grondal/GrondalFilmen.vue';
// import GrondalsHistoria from '@/views/grondal/GrondalsHistoria.vue';
// import Arkitekterna from '@/views/grondal/Arkitekterna.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/nyheter',
        component: NewsPage,
        name: 'Nyheter',
        enabled: false
    },
    {
        path: '/ellipsen',
        redirect: { name: 'Boka' },
    },
    {
        path: '/boka',
        component: Booking,
        name: 'Boka',
    },
    {
        path: '/foreningen',
        component: Foreningen,
        name: 'Föreningen',
        enabled: true,
        children: [
           {
               path: '',
               name: 'Föreningen',
               component: Foreningen
           },
        //    {
        //        path: 'arsredovisningar',
        //        name: 'Årsredovisningar',
        //        component: Arsredovisningar
        //     },
        //     {
        //         path: 'fastighetsskotsel-forvaltning',
        //         name: 'Fastighetsskötsel och Ekonomisk förvaltning',
        //         component: Forvaltning
        //     },
        //     {
        //         path: 'grannsamverkan',
        //         name: 'Grannsamverkan',
        //         component: Grannsamverkan
        //     },
        //     {
        //         path: 'kulturklassning',
        //         name: 'Kulturklassning',
        //         component: Kulturklassning
        //     },
        //     {
        //         path: 'styrelse',
        //         name: 'Styrelse',
        //         component: Styrelse
        //     },
        ]
    },
    // {
    //     path: '/grondal',
    //     component: Grondal,
    //     name: 'Gröndal',
    //     enabled: false,
    //     children: [
    //         { path: 'arkitekterna', name: 'Arkitekterna', component: Arkitekterna },
    //         { path: 'filmen-om-grondal', name: 'Filmen om Gröndal', component: GrondalFilmen },
    //         { path: 'grondals-historia', name: 'Gröndals historia', component: GrondalsHistoria },
    //     ]
    // },
    {
        path: '/tradgard',
        name: 'Trädgård',
        enabled: false,
    },
    {
        path: '/kontakt-felanmalan',
        component: Contact,
        name: 'Kontakt & Felanmälan'
    },
    {
        path: '/medlemmar',
        // eslint-disable-next-line
        beforeEnter(to, from, next) {
            window.location.href = "https://medlemmar.galjonsbilden.se/"
        },
        name: 'Medlemssidor',
        enabled: false
    },
    {
        path: '/',
        component: Home
    },
    // {
    //     path: '*',
    //     redirect: {
    //         path: '/'
    //     }
    // }
];

export default new VueRouter({
    mode: 'history',
    base: __dirname,
    routes
});